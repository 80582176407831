import { Injectable,EventEmitter,OnInit } from '@angular/core';
import { Observable,Subject } from 'rxjs';
import {JanusClient} from 'orkestraLib';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';



@Injectable()
export class JanusService {
  camerasList: any;
  dataService: DataService;
  trasnaction: string;
  session:string;
  pluginId:string;
  room: number;
  constructor(dataService:DataService,public http:HttpClient){

    this.dataService = dataService;
  }
  init (){
    if (!window.FlexJanus){
      window.FlexJanus  = JanusClient(this,environment.janusIp,this.room);
      window.FlexJanus.init(this)
    }
    document.addEventListener('JanusReady',this.janusReadyListener.bind(this));
    document.addEventListener('newStream',this.newStreamListener.bind(this));
    document.addEventListener('updateStream',this.newStreamListener.bind(this));
  }
  janusReadyListener (e){
    setTimeout(()=>{
      this.camerasList = (window as any).FlexJanus.feeds.map((ev)=>{
        return {name:ev.rfdisplay,id:ev.id};
      })
      this.dataService.setWebRtcStreams(this.camerasList);
      let cams = this.camerasList.filter((cam)=>{ if(cam.name.includes('cam'))return true;});
      let actorsCams = this.camerasList.filter((cam)=>{ if(cam.name.includes('actor'))return true;});
      actorsCams.forEach((ac)=>{
        this.dataService.addActorMedia(ac.name,ac);
      })
      this.dataService.setCameras(cams);
    },2000);

  }
  newStreamListener (e){
    console.log(e);
    setTimeout(()=>{
      this.camerasList = (window as any).FlexJanus.feeds.map((ev)=>{
        return {name:ev.rfdisplay,id:ev.id};
      })
      this.dataService.setWebRtcStreams(this.camerasList);
      let cams = this.camerasList.filter((cam)=>{ if(cam.name.includes('cam'))return true;});
      let actorsCams = this.camerasList.filter((cam)=>{ if(cam.name.includes('actor'))return true;});
      actorsCams.forEach((ac)=>{
        this.dataService.addActorMedia(ac.name,ac);
      })
      this.dataService.setCameras(cams);
    },500);
  }
  createSession(){
    this.trasnaction = "dkididiediewewq1";
    let url:string = "https://"+environment.janusIp+":"+8089+"/janus";
    let options = {
      "janus":"create",
      "transaction":this.trasnaction
    }
    return this.http.post(url,options)
  }
  attachToPlugin(){
    let url:string = "https://"+environment.janusIp+":"+8089+"/janus/"+this.session;
    let options = {"janus":"attach","plugin":"janus.plugin.videoroom","transaction":this.trasnaction};
    return this.http.post(url,options)
  }
  getRooms(){
    return new Promise((resolve, reject)=>{
      this.connectToEndPoint().then(()=>{
        let url:string = "https://"+environment.janusIp+":"+8089+"/janus/"+this.session+"/"+this.pluginId;
        let options = {"janus": "message", "body": {"request": "list"}, "transaction": this.trasnaction}
        this.http.post(url,options).subscribe(ev=>{
          resolve(ev);
        },(error)=>reject("error"))

      })
    })
  }
  getParticipants(){
    return new Promise((resolve, reject)=>{
      this.connectToEndPoint().then(()=>{
        let url:string = "https://"+environment.janusIp+":"+8089+"/janus/"+this.session+"/"+this.pluginId;
        let options = {"janus": "message", "body": {"request": "create","room":this.room}, "transaction": this.trasnaction}
        this.http.post(url,options).subscribe(ev=>{
          resolve(ev);
        },(error)=>reject("error"))

      })
    })
  }
  async isRoomExists(room:number){
    return new Promise((resolve, reject)=>{
      this.getRooms().then((ev:any)=>{
        if (ev.plugindata.data.list.map(x=>{return x.room}).indexOf(room)!==-1) {
          this.room = room;
          resolve(true);
        }
        else resolve(false);
      },(error)=>{reject("getting janus rooms")})
    })
  }
  createRoom(room:number){
    this.room = room;
    return new Promise((resolve, reject)=>{
      this.connectToEndPoint().then(()=>{
        let url:string = "https://"+environment.janusIp+":"+8089+"/janus/"+this.session+"/"+this.pluginId;
        let options = {"janus": "message", "body": {"request": "create","room":room,"publishers":16,"videocodec":"h264","bitrate":0}, "transaction": this.trasnaction}
        this.http.post(url,options).subscribe(ev=>{
          resolve(ev);
        },(error)=>reject("error"))
      });
    })
  }
  cleanRooms (){
    this.getRooms().then((ev:any)=>{
        let list = ev.plugindata.data.list;
        list.forEach(el=>{
          this.removeRoom(el.room);
        })
    });
  }
  removeRoom(room:number){
    this.room = room;
   
        let url:string = "https://"+environment.janusIp+":"+8089+"/janus/"+this.session+"/"+this.pluginId;
        let options = {"janus": "message", "body": {"request": "destroy","room":room}, "transaction": this.trasnaction}
        this.http.post(url,options).subscribe(ev=>{
          console.log(ev);
        },(error)=>console.log("error"))

  }
    getUsingRoom():number{
    return this.room;
  }
  setRoomByName(name:string){
    this.room= name.split('').map(x=>{ return x.charCodeAt(0)}).reduce((acu,y)=> {return acu+y});
  }
  setRoomById(name:string){
    this.room= parseInt(name);
  }
  connectToEndPoint(){
    return new Promise((resolve, reject) =>{
      this.createSession().subscribe((ev)=>{
        this.session = (ev as any).data.id;
        this.attachToPlugin().subscribe((ev1)=>{
          this.pluginId = (ev1 as any).data.id;
          resolve("ok");
        },(error)=>reject("error attaching"))
      },(error)=>reject("error creating session"))
    })
  }

}
